// Production
const baseRestApiUrl = 'https://api.itsovertime.com';
const basePlatformsApiUrl = 'https://api.itsovertime.com';

// Local development
// const basePlatformsApiUrl = 'http://overtime.home:8082';
// const baseRestApiUrl = 'http://overtime.home:8081';

const Urls = {
	ApiBanners: `${baseRestApiUrl}/api/banners/v1/public?site=otboxing.com`,
	ApiEvents: `${baseRestApiUrl}/api/otx_events/v1/public`,
	ApiFighters: `${baseRestApiUrl}/api/otx_fighters/v1/public`,
	ApiFights: `${baseRestApiUrl}/api/otx_fights/v1/public`,
	ApiTournamentFights: `${baseRestApiUrl}/api/otx_fights/v1/public/tournament`,

	ApiPlatformsDrawbridgeCollections: `${basePlatformsApiUrl}/api/platforms/v1/drawbridge/collections`,
	ApiPlatformsDrawbridgeEvents: `${basePlatformsApiUrl}/api/platforms/v1/drawbridge/events`,
	ApiPlatformsKlaviyo: `${basePlatformsApiUrl}/api/platforms/v1/klaviyo/lists`,
	ApiPlatformsVivenuEventInfo: `${basePlatformsApiUrl}/api/platforms/v1/vivenu/events/info`,
	ApiPlatformsYoutube: `${basePlatformsApiUrl}/api/platforms/youtube/otx?limit=50`,
	ApiPlatformsYoutubeShorts: `${basePlatformsApiUrl}/api/platforms/youtube/otx_shorts?limit=6`,

	OvertimeImagesBase: 'https://images.overtime.tv',
	OvertimeImagesOtx: 'https://images.overtime.tv',

	SocialInstagram: 'https://www.instagram.com/overtimeboxing',
	SocialTikTok: 'https://www.tiktok.com/@overtimeboxing',
	SocialYoutube: 'https://www.youtube.com/@otx',

	VivenuEventBase: 'https://tickets.itsovertime.com/event',
	VivenuTicketHome: 'https://tickets.itsovertime.com',

	DraftKingsOtxBoxing: 'https://dksb.sng.link/As9kz/hotp/iehw',
} as const;

export default Urls;
