import React from 'react';
import ContentContainer from '~/src/components/global/ContentContainer';
import Html from '~/src/components/global/Html';
import Suspended from '~/src/components/global/Suspended';
import Bracket from './Bracket';
import Button from './Button';
import DraftkingsOddsWarning from './DraftkingsOddsWarning';
import EventsList from './EventsList';
import FightCard from './FightCard';
import FontShrinker from './FontShrinker';
import Markdown from './Markdown';
import PageHeading from './PageHeading';
import Photos from './Photos';
import SectionHeading from './SectionHeading';
import Spinner from './Spinner';
import Table from './Table';
import Tabs from './Tabs';
import TournamentFightCard from './TournamentFightCard';

/*
Important Note: Add all shared and global components to this file.

This shared index component should only ever be used in the routes file.

Including it in the routes file will force parcel to bundle all of the
css from the shared components into the app.css file.

This is important for server side rendering to correctly load css for the shared components.

If a component is shared across multiple routes, Parcel only bundles 
the css into the first (and only the first) <page>.css that it is used in.
Other routes/pages that use the shared component end up with no reference to the css file.

Example: If PageHeading is used on the Homepage and the About page, the css will only be 
bundled into the Homepage. The About page will not include the PageHeading css.

By importing and initializing this shared component into the routes file, Parcel will bundle all
 of the shared components css into the app.css file, where it will be accessible from all pages.
*/

const Shared = () => (
	<>
		<Button href={undefined}> </Button>
		<Bracket games={null} getGameView={null} next_game_property={null} />
		<TournamentFightCard fight={null} />
		<ContentContainer> </ContentContainer>
		<FightCard fight={null} tournamentFights={null} />
		<FontShrinker> </FontShrinker>
		<EventsList events={null} tournamentFights={null} />
		<Html assets={{}} children={''} />
		<Markdown text="" />
		<PageHeading> </PageHeading>
		<Photos />
		<SectionHeading> </SectionHeading>
		<Spinner />
		<Suspended> </Suspended>
		<Table headers={[]} currentHeader={''} rows={[]} />
		<Tabs links={[]} root={''} color={''} />
		<DraftkingsOddsWarning fights={[]} />
	</>
);

export default Shared;
