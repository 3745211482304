import { useEffect, useState } from 'react';

const localStorageKey = 'cookie_consent';

const GoogleTagManager = ({ gId }: { gId: string }) => {
	const [isGranted, setIsGranted] = useState<boolean>(false);

	useEffect(() => {
		setIsGranted(typeof window !== 'undefined' ? window.localStorage.getItem(localStorageKey) === 'true' : false);
	}, []);

	useEffect(() => {
		if (isGranted) {
			// Dynamically load the gtag.js script
			const script = document.createElement('script');
			script.src = `https://www.googletagmanager.com/gtag/js?id=${gId}`;
			script.async = true;
			document.head.appendChild(script);

			// Add the gtag configuration script
			script.onload = () => {
				const inlineScript = document.createElement('script');
				inlineScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gId}');
                `;
				document.head.appendChild(inlineScript);
			};
		}
	}, [isGranted, gId]);

	return null;
};

export default GoogleTagManager;
