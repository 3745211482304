export enum SaleStatuses {
	OnSale = 'onSale',
	Planned = 'planned',
	Past = 'past',
	SoldOut = 'soldOut',
}

export interface VEventsResponse {
	rows: VEvent[];
	total: number;
}

export interface VEvent {
	_id: string;
	sellerId: string;
	name: string;
	slogan: string;
	venueId: string;
	description: string;
	locationName: string;
	locationStreet: string;
	locationCity: string;
	locationPostal: string;
	locationCountry: string;
	image: string;
	ticketFooter: string;
	ticketBackground: string;
	ticketShopHeader: string;
	groups: Group[];
	discountGroups: DiscountGroup[];
	cartAutomationRules: CartAutomationRule[];
	posDiscounts: PosDiscount[];
	categories: Category[];
	tickets: Ticket[];
	createdAt: string;
	updatedAt: string;
	start: string;
	end: string;
	sellStart: string;
	sellEnd: string;
	maxAmount: number;
	maxAmountPerOrder: number;
	maxAmountPerCustomer: number;
	maxTransactionsPerCustomer: number;
	minAmountPerOrder: number;
	showCountdown: boolean;
	hideInListing: boolean;
	customSettings: CustomSettings;
	extraFields: ExtraField[];
	ticketExtraFields: TicketExtraField[];
	accentColor: string;
	pageStyle: string;
	showOtherEvents: boolean;
	underShops: UnderShop[];
	seating: Seating;
	customTextConfig: CustomTextConfig;
	eventType: string;
	childEvents: string[];
	url: string;
	tags: string[];
	seoSettings: SeoSettings;
	posCode: string;
	extraInformation: ExtraInformation;
	customCharges: CustomCharges2;
	gallery: Gallery[];
	video: Video;
	soldOutFallback: SoldOutFallback;
	ticketDesign: TicketDesign;
	checkinInformation: CheckinInformation;
	tracking: Tracking;
	hardTicketSettings: HardTicketSettings;
	dataRequestSettings: DataRequestSettings;
	styleOptions: StyleOptions2;
	geoCode: GeoCode;
	accountSettings: AccountSettings;
	reservationSettings: ReservationSettings2;
	upsellSettings: UpsellSettings;
	repetitionSettings: RepetitionSetting[];
	rootId: string;
	resources: Resource[];
	daySchemes: DayScheme[];
	daySchemeId: string;
	meta: Meta;
	timezone: string;
}

export interface Group {
	_id: string;
	name: string;
	tickets: string[];
}

export interface DiscountGroup {
	_id: string;
	name: string;
	rules: Rule[];
	discountType: string;
	value: number;
}

export interface Rule {
	_id: string;
	group: string;
	type: string;
	min: number;
	max: number;
}

export interface CartAutomationRule {
	_id: string;
	name: string;
	triggerType: string;
	triggerTargetGroup: string;
	thenType: string;
	thenTargets: ThenTarget[];
}

export interface ThenTarget {
	_id: string;
	thenTargetGroup: string;
	thenTargetTicketType: string;
	thenTargetMin: number;
	thenTargetMax: number;
}

export interface PosDiscount {
	_id: string;
	name: string;
	discountType: string;
	value: number;
}

export interface Category {
	_id: string;
	active: boolean;
	amount: number;
	availabilityIndicator: string;
	description: string;
	listWithoutSeats?: boolean;
	maxAmountPerOrder?: number;
	name: string;
	recommendedTicket: string;
	ref: string;
	seatingReference?: string;
	v: number;
}

export interface Ticket {
	_id: string;
	active: boolean;
	amount: number;
	barcodePrefix: string;
	categoryRef: string;
	color: string;
	conditionalAvailability: boolean;
	conditionalAvailabilityMode: string;
	description: string;
	enableHardTicketOption: boolean;
	entryPermissions: any[];
	expirationSettings: ExpirationSettings;
	forceHardTicketOption: boolean;
	ignoredForStartingPrice: boolean;
	ignoreForMaxAmounts: boolean;
	image: string;
	maxAmountPerOrder: number;
	minAmountPerOrder: number;
	minAmountPerOrderRule: number;
	name: string;
	posActive: boolean;
	price: number;
	priceCategoryId: string;
	requiresExtraFields: boolean;
	requiresPersonalization: boolean;
	rules: Rule2[];
	sortingKey: number;
	styleOptions: StyleOptions;
	taxRate: number;
	ticketBackground: string;
	v: number;
	id: string;
}

export interface Rule2 {
	_id: string;
	ticketGroup: string;
	min: number;
	max: number;
}

interface StyleOptions {
	thumbnailImage?: string;
	hiddenInSelectionArea?: boolean;
}

export interface ExpirationSettings {}

export interface CustomSettings {}

export interface ExtraField {
	_id: string;
	allowedTicketTypes: any[];
	applyToCustomer: boolean;
	conditions: any[];
	deleted: boolean;
	description: string;
	name: string;
	onlyForCertainTicketTypes: boolean;
	options: string[];
	printable: boolean;
	required: boolean;
	slug: string;
	type: string;
}

export interface TicketExtraField {
	_id: string;
	name: string;
	description: string;
	required: boolean;
	deleted: boolean;
	type: string;
	options: string[];
	onlyForCertainTicketTypes: boolean;
	allowedTicketTypes: any[];
	printable: boolean;
}

export interface UnderShop {
	_id: string;
	name: string;
	active: boolean;
	tickets: Ticket2[];
	sellStart: string;
	sellEnd: string;
	maxAmount: number;
	maxAmountPerOrder: number;
	minAmountPerOrder: number;
	ticketShopHeaderText: string;
	customCharges: CustomCharges;
	seatingContingents: string[];
	availabilityMode: string;
	reservationSettings: ReservationSettings;
	customerTags: any[];
}

export interface Ticket2 {
	_id: string;
	baseTicket: string;
	name: string;
	description: string;
	price: number;
	amount: number;
	active: boolean;
}

export interface CustomCharges {}

export interface ReservationSettings {
	option: string;
}

export interface Seating {
	_id: string;
	active: boolean;
	eventKey: string;
	eventId: string;
	seatMapId: string;
	revisionId: string;
	orphanConfiguration: OrphanConfiguration;
	spaceManagementConfiguration: SpaceManagementConfiguration;
	contingents: string[];
	availabilityMode: string;
}

export interface OrphanConfiguration {
	_id: string;
	minSeatDistance: number;
	edgeSeatsOrphaning: boolean;
}

export interface SpaceManagementConfiguration {
	_id: string;
	enabled: boolean;
	blockSeatNumberAround: number;
	blockRowNumberAround: number;
}

export interface CustomTextConfig {
	_id: string;
	buyTicketsCTA: string;
}

export interface SeoSettings {
	_id: string;
	tags: string[];
	noIndex: boolean;
	title: string;
	description: string;
}

export interface ExtraInformation {
	_id: string;
	type: string;
	category: string;
	subCategory: string;
}

export interface CustomCharges2 {
	_id: string;
	outerChargeVar: number;
	innerChargeVar: number;
	outerChargeFix: number;
	innerChargeFix: number;
	posOuterChargeFix: number;
	posOuterChargeVar: number;
	cartOuterChargeFix: number;
}

export interface Gallery {
	_id: string;
	title: string;
	description: string;
	copyright: string;
	index: number;
	image: string;
}

export interface Video {
	youtubeID: string;
}

export interface SoldOutFallback {
	_id: string;
	soldOutFallbackType: string;
	soldOutFallbackLink: string;
}

export interface TicketDesign {
	_id: string;
	useCustomDesign: boolean;
	customDesignURL: string;
	footerDesignURL: string;
	disclaimer: string;
	infoColor: string;
	showTimeRange: boolean;
	hideDates: boolean;
	hideTimes: boolean;
}

export interface CheckinInformation {
	_id: string;
	checkinStarts: string;
}

export interface Tracking {
	facebookPixel: FacebookPixel;
	tagging: Tagging;
	enabled: boolean;
	sellerId: string;
	facebookPixelId: string;
	gTagManagerGtmId: string;
}

export interface FacebookPixel {
	active: boolean;
	pixelId: string;
}

export interface Tagging {
	enabled: boolean;
	tags: string[];
}

export interface HardTicketSettings {
	_id: string;
	enabled: boolean;
	fulfillmentType: string;
	printingMethod: string;
	hardTicketOuterCharge: number;
	hardTicketInnerCharge: number;
	hardTicketPreviewURL: string;
	promotionName: string;
	promotionText: string;
	requiredDays: number;
}

export interface DataRequestSettings {
	_id: string;
	posPersonalization: string;
	repersonalization: boolean;
	repersonalizationAllowed: boolean;
	repersonalizationDeadline: RepersonalizationDeadline;
	requiresExtraFields: boolean;
	requiresPersonalization: boolean;
	skipAddressInfo: boolean;
}

export interface StyleOptions2 {
	headerStyle: string;
	hideLocationMap: boolean;
	categoryAlignment: number;
	showAvailabilityIndicator: boolean;
	availabilityIndicatorThresholds: number[];
	hideStartingPrice: boolean;
	useTicketTypeWizard: boolean;
	brandOne: string;
	brandTwo: string;
}

export interface GeoCode {
	_id: string;
	lat: number;
	lng: number;
}

export interface AccountSettings {
	_id: string;
	enforceAccounts: boolean;
}

export interface ReservationSettings2 {
	option: string;
}

export interface UpsellSettings {
	_id: string;
	active: boolean;
	productStream: string;
	headerImage: string;
}

export interface RepetitionSetting {
	every: number;
	unit: string;
	repeatsOn: string[];
	from: string;
	to: string;
}

export interface Resource {
	_id: string;
	name: string;
	capacity: number;
}

export interface DayScheme {
	_id: string;
	name: string;
	color: string;
	usesSlots: boolean;
	slots: Slot[];
	offers: Offers2;
}

export interface Slot {
	_id: string;
	start: string;
	amount: number;
	offers: Offers;
}

export interface Offers {}

export interface Offers2 {}

export interface Meta {}

export interface EventInfoResponse {
	_id: string;
	eventType: string;
	name: string;
	start: string;
	end: string;
	url: string;
	description: string;
	slogan: string;
	image: string;
	locationName: string;
	accentColor: string;
	startingPrice: number;
	sellStart: string;
	extraFields: ExtraField[];
	ticketExtraFields: any[];
	seating: boolean;
	cXv: number;
	cXf: number;
	ccXf: number;
	ccfXf: number;
	sellerId: string;
	taxRate: number;
	currency: string;
	seller: Seller;
	reservationSettings: ReservationSettings;
	timezone: string;
	saleStatus: string;
	v: number;
	seatingChildEventIds: any[];
	tickets: Ticket[];
	categories: Category[];
	ticketShopHeader: string;
	seatingEventId: string;
	seatingConfigurations: SeatingConfigurations;
	contingents: any[];
	max: number;
	min: number;
	groups: Group[];
	cartAutomationRules: CartAutomationRule[];
	discountGroups: any[];
	theme: string;
	styleOptions: StyleOptions2;
	ticketShopHeaderText: string;
	showOtherEvents: boolean;
	video: Video;
	gallery: Gallery[];
	checkinInformation: CheckinInformation;
	location: Location;
	hardTicketSettings: HardTicketSettings;
	tracking: Tracking;
	dataRequestSettings: DataRequestSettings;
	seoSettings: SeoSettings;
	soldOutFallback: SoldOutFallback;
	accountSettings: AccountSettings;
	customTextConfig: CheckinInformation;
	upsellSettings: UpsellSettings;
	subscriptionSettings: Video;
}

interface RepersonalizationDeadline {
	unit: string;
	offset: number;
}

interface Location {
	locationName: string;
	locationCity: string;
	locationPostal: string;
	locationCountry: string;
	locationStreet: string;
	geoCode: GeoCode;
}

interface SeatingConfigurations {
	availabilityMode: string;
	renderer: string;
	bestAvailableSeatingConfiguration: BestAvailableSeatingConfiguration;
	orphanConfiguration: OrphanConfiguration;
	spaceManagementConfiguration: SpaceManagementConfiguration;
}

interface BestAvailableSeatingConfiguration {
	enabled: boolean;
	enforced: boolean;
}

interface StyleOptions {
	thumbnailImage?: string;
	hiddenInSelectionArea?: boolean;
}

interface Seller {
	_id: string;
	name: string;
	description: string;
	image: string;
	documentImage: string;
	url: string;
	supportUrl: string;
	customLogo: string;
	defaultLanguage: string;
	locationCountry: string;
	taxSetting: TaxSetting;
}

interface TaxSetting {
	localTaxName: string;
	taxCalculationMethod: string;
}
