import _ from 'lodash';
import { FetchResult, wrappedFetch } from '../models';

// Suspense relies on the promise being evaluated to be identical across renders
// However, when the promise resolves, Suspense rerenders all children from scratch
// This destroys any promises kept in state, whether its useState/useRef/useMemo
// To avoid this, we keep references to the promises outside of the React tree
// Take it up with the React team who designed Suspense
const fetches: { [k: string]: FetchResult<any> } = {};

export default function useFetch<T>({ url, key }: { url: string; key?: string }) {
	const fetchResult = fetches[url] ?? wrappedFetch<T>(url, undefined, key);
	if (_.isNil(fetches[url])) {
		fetches[url] = fetchResult;
		//Clear the fetch once per minute so the server doesn't render old data forever
		setTimeout(() => (fetches[url] = null), 1000 * 60 * 5);
	}
	return fetchResult as FetchResult<T>;
}
