import React from 'react';
import { FightsContainDraftkingsId, OtxFight } from '~/src/models/OtxFight';
import './DraftkingsOddsWarning.scss';

const DraftkingsOddsWarning = ({
	fights,
	additionalStyles,
}: {
	fights: OtxFight[];
	additionalStyles?: React.CSSProperties;
}) => {
	if (!FightsContainDraftkingsId(fights)) return null;

	return (
		<p className="odds-warning" style={additionalStyles}>
			Odds are subject to change. Visit DraftKings for the most up-to-date lines.
		</p>
	);
};

export default DraftkingsOddsWarning;
