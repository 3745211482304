import React, { useContext, useEffect } from 'react';
import { imgFaviconSvg, imgFavicon_16x16Png, imgFavicon_32x32Png, imgIconAppleTouchPng } from '~/src/images';
import config from '~/src/lib/config';
import styles from '~/src/lib/styles.json';
import DocumentContext from '~/src/overtime-lib/src/www/contexts/Document';
import CookieConsent from '../shared/CookieConsent';
import GoogleTagManager from '../shared/GoogleTagManager';
import './Html.scss';

export default function Html({
	assets = {},
	children = null,
}: {
	assets: { [key: string]: any };
	children: React.ReactNode;
}) {
	const documentContext = useContext(DocumentContext);
	const otxDescription = 'OTX is the platform for the next generation of boxers.';
	const description = (documentContext.description ?? otxDescription).replace(/"/g, '&quot;').replace(/\n/g, '\\n');

	const rawTitle = documentContext.title;
	const title = [rawTitle, 'OTX'].filter(Boolean).join(' | ');

	const color = documentContext.color ?? styles.COLOR.BRAND_PRIMARY;

	const canonicalUrl = `https://otboxing.com${documentContext.canonicalPath ?? documentContext.location.pathname}${
		documentContext.location.search
	}`;

	const googleMeasurementId = 'G-08E3R12TF9';

	useEffect(() => {
		//Mobile browsers lie about vh so we're stuck with this nonsense
		window.onresize = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
		window.onresize = () => {
			//All this is necessary to handle scrollbar width problems
			document.documentElement.style.setProperty('--app-width', `${document.body.clientWidth}px`);
		};
		const observer = new ResizeObserver((_el) => window.onresize(null));
		observer.observe(document.body);

		window.onresize(null);
		return () => {
			window.onresize = null;
			observer.unobserve(document.body);
		};
	}, []);

	return (
		<html lang="en">
			<head>
				<title>{title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="UTF-8" />
				<meta name="google-site-verification" content="_Npfn_L-weRdy7khF5vLyZVtGDv7o59P15vRfRpuFDw" />
				<meta name="google-site-verification" content="2kA3U3XEwCVR8zHoKOR9o12DITn1nJApUIJqQ1CKH-s" />
				<meta name="google-site-verification" content="AKheGLF0n028K_um_R3PAQHuImh2e_WEe3RXqzo5NaA" />
				<meta property="fb:app_id" content={config('OVERTIME_FB_APP')} />
				<meta property="og:site_name" content="OTX" />
				<meta property="og:title" content={rawTitle} />
				<meta property="og:description" content={description} />
				{/* {imageUrls.map((i) => (
					<meta property="og:image" key={i} content={i} />
				))} */}
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href={imgIconAppleTouchPng} />
				<link rel="icon" type="image/svg+xml" href={imgFaviconSvg} />
				<link rel="icon" type="image/png" sizes="32x32" href={imgFavicon_32x32Png} />
				<link rel="icon" type="image/png" sizes="16x16" href={imgFavicon_16x16Png} />
				{/* <link rel="manifest" href="/site.webmanifest" /> */}
				<link rel="mask-icon" href={imgFaviconSvg} color={styles.COLOR.BLACK} />
				<link rel="canonical" href={canonicalUrl} />
				<meta name="msapplication-TileColor" content={color} />
				<meta name="theme-color" content={color} />
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'http://schema.org',
							'@type': 'Organization',
							name: 'OTX',
							alternateName: 'Overtime Boxing',
							description: otxDescription,
							url: 'https://otboxing.com',
							address: {
								streetAddress: '230 17th St NW',
								addressLocality: 'Atlanta',
								addressRegion: 'GA',
								postalCode: '30363',
							},
							parentOrganization: {
								name: 'Overtime',
								url: 'https://overtime.tv',
							},
							sameAs: [],
						}),
					}}
				/>
				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<style>{`
					
				`}</style>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<link
					rel="preload"
					href="https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2"
					as="font"
					type="font/woff2"
					crossOrigin="anonymous"
				/>{' '}
				<link rel="stylesheet" href="/public/fortawesome.css" />
				<link rel="stylesheet" href="/public/app.css" />
				{Object.values(assets)
					.filter((a) => a.endsWith('css'))
					.map((a) => (
						<link rel="stylesheet" key={a} href={a} />
					))}
				<GoogleTagManager gId={googleMeasurementId} />
			</head>
			<body>
				<div id="root">{children}</div>
				<CookieConsent isDark={false} />
				<script
					dangerouslySetInnerHTML={{
						__html: `window.assetManifest = ${JSON.stringify(assets)};window.fetch = window.fetch.bind(window);`,
					}}
				/>
				<script dangerouslySetInnerHTML={{ __html: `window._documentContext = ${JSON.stringify(documentContext)};` }} />
				{Object.values(assets)
					.filter((a) => a.endsWith('js'))
					.map((a) => (
						<script key={a} src={a} />
					))}
				<script defer src="/public/app.js" />
				{/* <script type="text/javascript" src="https://vivenu.com/web/deliver/js/v1/embed.js" /> */}
			</body>
		</html>
	);
}

// export default Html;
