import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useState } from 'react';
import { imgOtxLogoSvg } from '~/src/images';
import { RoutePaths } from '~/src/www/routes';
import Urls from '../../../../constants/urls';
import useFetch from '../../../../hooks/useFetch';
import { OtxEvent } from '../../../../models/OtxEvent';
import './Navigation.scss';

const Navigation = () => {
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

	const homepageLink = RoutePaths.Index;

	// Get the tickets URL if the event is not at OTE Arena
	const events = useFetch<OtxEvent[]>({ url: Urls.ApiEvents, key: 'otx_events' })?.result.read();
	const latestEvent = _.orderBy(events, (e) => e.starts_at, 'desc').at(0);
	const eventTicketsUrl = latestEvent.cta_url
		? latestEvent.cta_url
		: latestEvent.vivenu_id
			? `${Urls.VivenuEventBase}/${latestEvent.vivenu_id}`
			: RoutePaths.Tickets;
	const ticketsLink = latestEvent.location_name !== 'OTE Arena' ? eventTicketsUrl : RoutePaths.Tickets;

	const links: { text: string; url: string }[] = [
		{ text: 'Fighters', url: RoutePaths.Fighters },
		// { text: 'Tournament', url: RoutePaths.Tournament },
		{ text: 'Schedule', url: RoutePaths.Events },
		{ text: 'Results', url: RoutePaths.Results },
		{ text: 'Tickets', url: Urls.VivenuOtxSecretShop },
		{ text: 'About', url: RoutePaths.About },
	];

	const toggleMobileMenu = () => {
		setIsMobileMenuVisible(!isMobileMenuVisible);
	};

	return (
		<div className="header-navigation">
			<div className="header-navigation__container">
				<a className="logo" href={homepageLink}>
					<img src={imgOtxLogoSvg} alt="OTX" />
				</a>
				{links.length > 0 && (
					<nav className="desktop">
						{links.map((l) => (
							<a className="link" href={l.url} key={l.text}>
								{l.text}
							</a>
						))}
					</nav>
				)}
				<div className={`toggle ${isMobileMenuVisible ? 'toggle--open' : ''}`} onClick={toggleMobileMenu}>
					<div className="menu-icon">
						<FontAwesomeIcon icon={isMobileMenuVisible ? faXmark : faBars} color={'white'} size={'2x'} />
					</div>
				</div>
			</div>
			<nav className={`mobile ${isMobileMenuVisible ? 'mobile--open' : ''}`}>
				{links.map((l) => (
					<a className="link" href={l.url} key={l.text}>
						{l.text}
					</a>
				))}
			</nav>
		</div>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Navigation;
