import { DateTime } from 'luxon';
import { OtxFight } from './OtxFight';
import { OvertimeEntity } from './OvertimeEntity';
import { OvertimeEntityLocation } from './OvertimeEntityLocation';

export interface OtxEvent extends OvertimeEntity, OvertimeEntityLocation {
	cta_text: string;
	cta_url: string;
	dazn_url: string;
	description: string;
	dj_name: string;
	drawbridge_collection_id: string;
	drawbridge_event_name: string;
	image_path: string;
	is_18_plus: boolean;
	is_alcohol_served: boolean;
	is_hidden: boolean;
	is_sold_out: boolean;
	location_name: string;
	location_website_url: string;
	otx_number: number;
	starts_at_timezone: string;
	starts_at_utc_offset: number;
	starts_at: string;
	title: string;
	vivenu_id: string;

	otx_fights: OtxFight[];
}

export const GetDateString = (timestamp: Date, timezone?: string): string => {
	const locale = timezone ? timezone : 'default';
	const dateString = `${timestamp.toLocaleString(locale, { month: 'short', day: 'numeric', year: 'numeric' })}`;
	return dateString;
};

export const GetDateTimeString = (
	eventDate: Date,
	outputTimezone: string = undefined,
	includeTime: boolean = true,
	includeYear: boolean = false,
	includeWeekday: boolean = true,
): string => {
	// Note: outputTimezone should be represented in the full TZ identifier (timeZoneId) to properly handle daylight savings time
	// Use the Google Maps Time Zone API to retrieve the timeZoneId from latitude/longitude coordinates
	// https://developers.google.com/maps/documentation/timezone/overview
	// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
	// Example: Use 'America/New_York' -- do not use 'EST' since this will be incorrect for parts of the year when New York is on EDT

	// If no outputTimezone is provided, we default to New York time on the server
	const timezone = outputTimezone ?? typeof window === 'undefined' ? 'America/New_York' : undefined;

	const weekday = eventDate.toLocaleString('default', { weekday: 'short', timeZone: timezone });
	const month = eventDate.toLocaleString('default', { month: 'short', timeZone: timezone });
	const year = eventDate.toLocaleString('default', { year: 'numeric', timeZone: timezone });
	const date = GetNumberWithSuffix(+eventDate.toLocaleString('default', { day: 'numeric', timeZone: timezone }));
	const time = eventDate.toLocaleString('default', { timeStyle: 'short', timeZone: timezone });
	const timezoneName = eventDate
		.toLocaleString('default', { timeStyle: 'long', timeZone: timezone })
		.split(' ')
		.slice(-1);

	// Example output format:
	// Mon Aug 7th / 3:00 PM EDT
	const dateParts = [includeWeekday ? weekday : null, `${month} ${date}`, includeYear ? year : null]
		.filter(Boolean) // Remove null or undefined parts
		.join(', '); // Join with commas

	// if includeTime is false, we return the date only otherwise add '/ time to the end'
	const dateTimeString = `${dateParts}${includeTime ? ` / ${time} ${timezoneName}` : ''}`;
	return dateTimeString;
};

const GetNumberWithSuffix = (number: number) => {
	return `${number}${GetNumberSuffix(number)}`;
};

const GetNumberSuffix = (number: number) => {
	const lastDigit = number % 10;
	const last2Digits = number % 100;
	if (lastDigit === 1 && last2Digits != 11) return 'st';
	if (lastDigit === 2 && last2Digits != 12) return 'nd';
	if (lastDigit === 3 && last2Digits != 13) return 'rd';
	return 'th';
};

export const IsUpcoming = (event: OtxEvent) => {
	const eventDate = DateTime.fromISO(event.starts_at);
	const eventDiffMilliseconds = eventDate.diffNow().milliseconds;
	const isUpcoming = eventDiffMilliseconds > 0;
	return isUpcoming;
};

export const IsInProgress = (event: OtxEvent) => {
	const millisecondsFor8Hours = 8 * 60 * 60 * 1000;
	const eventDate = DateTime.fromISO(event.starts_at);
	const eventDiffMilliseconds = eventDate.diffNow().milliseconds;
	const isInProgress = eventDiffMilliseconds < 0 && eventDiffMilliseconds > millisecondsFor8Hours * -1;
	return isInProgress;
};

export const IsInResults = (event: OtxEvent) => {
	const millisecondsFor8Hours = 8 * 60 * 60 * 1000;
	// We decided to keep past events at the front of the list for 5 days instead of 8 hours
	const millisecondsFor5Days = 5 * 24 * 60 * 60 * 1000;
	const eventDate = DateTime.fromISO(event.starts_at);
	const eventDiffMilliseconds = eventDate.diffNow().milliseconds;
	const isInResults =
		eventDiffMilliseconds < 0 &&
		eventDiffMilliseconds < millisecondsFor8Hours * -1 &&
		eventDiffMilliseconds > millisecondsFor5Days * -1;
	return isInResults;
};

export const IsInPast = (event: OtxEvent) => {
	const millisecondsFor8Hours = 8 * 60 * 60 * 1000;
	// We decided to keep past events at the front of the list for 5 days instead of 8 hours
	const millisecondsFor5Days = 5 * 24 * 60 * 60 * 1000;
	const eventDate = DateTime.fromISO(event.starts_at);
	const eventDiffMilliseconds = eventDate.diffNow().milliseconds;
	const isInPast = eventDiffMilliseconds < millisecondsFor5Days * -1;
	return isInPast;
};

export const HasAllFightResults = (event: OtxEvent) => {
	const hasAllFightResults = event.otx_fights?.length > 0 && event.otx_fights?.every((f) => f.otx_winner_id);
	return hasAllFightResults;
};
