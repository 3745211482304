export { default as imgBrandsC4Svg } from 'url:~/src/images/brands/c4.svg';
export { default as imgBrandsDaznSvg } from 'url:~/src/images/brands/dazn.svg';
export { default as imgBrandsDraftKingsSvg } from 'url:~/src/images/brands/draft_kings.svg';
export { default as imgBrandsSubwaySvg } from 'url:~/src/images/brands/subway.svg';
export { default as imgBrandsTheGeneralSvg } from 'url:~/src/images/brands/the_general.svg';
export { default as imgFavicon_16x16Png } from 'url:~/src/images/favicon-16x16.png';
export { default as imgFavicon_32x32Png } from 'url:~/src/images/favicon-32x32.png';
export { default as imgFaviconSvg } from 'url:~/src/images/favicon.svg';
export { default as imgIcon_18UpSvg } from 'url:~/src/images/icon_18_up.svg';
export { default as imgIconAppleTouchPng } from 'url:~/src/images/icon_apple-touch.png';
export { default as imgIconBellSvg } from 'url:~/src/images/icon_bell.svg';
export { default as imgIconBeltSvg } from 'url:~/src/images/icon_belt.svg';
export { default as imgIconDollarSignSvg } from 'url:~/src/images/icon_dollar_sign.svg';
export { default as imgIconDrinkSvg } from 'url:~/src/images/icon_drink.svg';
export { default as imgIconGloveSvg } from 'url:~/src/images/icon_glove.svg';
export { default as imgIconHeadphonesSvg } from 'url:~/src/images/icon_headphones.svg';
export { default as imgIconLocationPinSvg } from 'url:~/src/images/icon_location_pin.svg';
export { default as imgIconLockSvg } from 'url:~/src/images/icon_lock.svg';
export { default as imgIconMoneyRoundSvg } from 'url:~/src/images/icon_money_round.svg';
export { default as imgIconPlaySvg } from 'url:~/src/images/icon_play.svg';
export { default as imgIconRingSvg } from 'url:~/src/images/icon_ring.svg';
export { default as imgIconScoreCardSvg } from 'url:~/src/images/icon_score_card.svg';
export { default as imgIconStopwatchSvg } from 'url:~/src/images/icon_stopwatch.svg';
export { default as imgIconStreamingSvg } from 'url:~/src/images/icon_streaming.svg';
export { default as imgIconTicketBarSeatingSvg } from 'url:~/src/images/icon_ticket_bar_seating.svg';
export { default as imgIconTicketFloorSeatsSvg } from 'url:~/src/images/icon_ticket_floor_seats.svg';
export { default as imgIconTicketStadiumSeatsSvg } from 'url:~/src/images/icon_ticket_stadium_seats.svg';
export { default as imgIconTicketStandingRoomSvg } from 'url:~/src/images/icon_ticket_standing_room.svg';
export { default as imgIconTicketTableSeatingSvg } from 'url:~/src/images/icon_ticket_table_seating.svg';
export { default as imgIconTrophySvg } from 'url:~/src/images/icon_trophy.svg';
export { default as imgMediaWebp } from 'url:~/src/images/media.webp';
export { default as imgOtx_1x1ArenaWebp } from 'url:~/src/images/otx_1x1_arena.webp';
export { default as imgOtxAboutWebp } from 'url:~/src/images/otx_about.webp';
export { default as imgOtxBrandmarkBlackPng } from 'url:~/src/images/otx_brandmark_black.png';
export { default as imgOtxBrandmarkShieldPng } from 'url:~/src/images/otx_brandmark_shield.png';
export { default as imgOtxBrokenWhiteSvg } from 'url:~/src/images/otx_broken_white.svg';
export { default as imgOtxLogoSvg } from 'url:~/src/images/otx_logo.svg';
export { default as imgOtxNumbersOtx_1Svg } from 'url:~/src/images/otx_numbers/otx_1.svg';
export { default as imgOtxNumbersOtx_10Svg } from 'url:~/src/images/otx_numbers/otx_10.svg';
export { default as imgOtxNumbersOtx_11Svg } from 'url:~/src/images/otx_numbers/otx_11.svg';
export { default as imgOtxNumbersOtx_12Svg } from 'url:~/src/images/otx_numbers/otx_12.svg';
export { default as imgOtxNumbersOtx_2Svg } from 'url:~/src/images/otx_numbers/otx_2.svg';
export { default as imgOtxNumbersOtx_3Svg } from 'url:~/src/images/otx_numbers/otx_3.svg';
export { default as imgOtxNumbersOtx_4Svg } from 'url:~/src/images/otx_numbers/otx_4.svg';
export { default as imgOtxNumbersOtx_5Svg } from 'url:~/src/images/otx_numbers/otx_5.svg';
export { default as imgOtxNumbersOtx_6Svg } from 'url:~/src/images/otx_numbers/otx_6.svg';
export { default as imgOtxNumbersOtx_7Svg } from 'url:~/src/images/otx_numbers/otx_7.svg';
export { default as imgOtxNumbersOtx_8Svg } from 'url:~/src/images/otx_numbers/otx_8.svg';
export { default as imgOtxNumbersOtx_9Svg } from 'url:~/src/images/otx_numbers/otx_9.svg';
export { default as imgOtxVipPhotosWebp } from 'url:~/src/images/otx_vip_photos.webp';
export { default as imgOtxWebPromoMp4 } from 'url:~/src/images/otx_web_promo.mp4';
export { default as imgPlaceholdersFighterFemaleBluePng } from 'url:~/src/images/placeholders/fighter_female_blue.png';
export { default as imgPlaceholdersFighterFemaleBlueSideWebp } from 'url:~/src/images/placeholders/fighter_female_blue_side.webp';
export { default as imgPlaceholdersFighterFemaleRedPng } from 'url:~/src/images/placeholders/fighter_female_red.png';
export { default as imgPlaceholdersFighterFemaleRedSideWebp } from 'url:~/src/images/placeholders/fighter_female_red_side.webp';
export { default as imgPlaceholdersFighterMaleBluePng } from 'url:~/src/images/placeholders/fighter_male_blue.png';
export { default as imgPlaceholdersFighterMaleBlueSideWebp } from 'url:~/src/images/placeholders/fighter_male_blue_side.webp';
export { default as imgPlaceholdersFighterMaleRedPng } from 'url:~/src/images/placeholders/fighter_male_red.png';
export { default as imgPlaceholdersFighterMaleRedSideWebp } from 'url:~/src/images/placeholders/fighter_male_red_side.webp';
export { default as imgPlaceholdersKeyArtWebp } from 'url:~/src/images/placeholders/key_art.webp';
export { default as imgTournamentBackgroundWebp } from 'url:~/src/images/tournament_background.webp';
export { default as imgTournamentBackgroundVerticalWebp } from 'url:~/src/images/tournament_background_vertical.webp';
export { default as imgTournamentLogoSvg } from 'url:~/src/images/tournament_logo.svg';
export { default as imgTournamentPageLogoSvg } from 'url:~/src/images/tournament_page_logo.svg';
export { default as imgVenuesCoreyCenterWebp } from 'url:~/src/images/venues/corey_center.webp';
export { default as imgVenuesOteArenaWebp } from 'url:~/src/images/venues/ote_arena.webp';
export { default as imgVenuesOteArenaSeatMapWebp } from 'url:~/src/images/venues/ote_arena_seat_map.webp';
export { default as imgVenuesRedOwlBoxingWebp } from 'url:~/src/images/venues/red_owl_boxing.webp';
export { default as imgVenuesRedOwlBoxingSeatMapWebp } from 'url:~/src/images/venues/red_owl_boxing_seat_map.webp';
export { default as imgVipPhotosLockedPng } from 'url:~/src/images/vip_photos_locked.png';
