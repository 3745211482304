import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import './SectionHeading.scss';

const SectionHeading = ({
	children,
	link = null,
	url = null,
	borderTop = true,
}: {
	children: ReactNode;
	link?: string;
	url?: string;
	borderTop?: boolean;
}) => {
	return (
		<div className={`section-heading${borderTop ? '' : ' section-heading--no-border'}`}>
			<h3 className="heading">{children}</h3>
			{!!link && (
				<a className="link" href={url}>
					{link}
					<FontAwesomeIcon icon={faArrowRight} />
				</a>
			)}
		</div>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default SectionHeading;
