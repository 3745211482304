import unfetch from 'isomorphic-unfetch';

export interface OvertimeModel {
	id: string;
	created_at: string;
	updated_at: string;
}

export interface OvertimeEntityLocation {
	latitude: number;
	longitude: number;
	address: string;
	city: string;
	state: string;
	zip: string;
	country: string;
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const fetch = unfetch;
export type FetchResult<T> = { result: { read(): T }; fetched?: Date };
export function wrappedFetch<T>(url, options, key?: string): FetchResult<T> {
	return {
		result: wrapPromise(
			wait(0)
				.then(() => fetch(url, { ...options, method: options?.method ?? 'GET' }))
				.then((r) => r.json())
				.then((o) => (key ? o[key] : o)),
		),
		fetched: new Date(),
	};
}

export function wrapPromise<T>(promise: Promise<T>) {
	let status = 'pending';
	let result: T;
	let error;
	let suspender = promise.then(
		(r) => {
			status = 'success';
			result = r;
		},
		(e: Error) => {
			status = 'error';
			error = e;
		},
	);
	return {
		read() {
			if (status === 'pending') {
				throw suspender;
			} else if (status === 'error') {
				throw error;
			} else if (status === 'success') {
				return result;
			}
		},
	};
}
