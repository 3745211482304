import React from 'react';
import {
	imgOtxLogoSvg,
	imgOtxNumbersOtx_10Svg,
	imgOtxNumbersOtx_11Svg,
	imgOtxNumbersOtx_12Svg,
	imgOtxNumbersOtx_13Svg,
	imgOtxNumbersOtx_14Svg,
	imgOtxNumbersOtx_15Svg,
	imgOtxNumbersOtx_16Svg,
	imgOtxNumbersOtx_17Svg,
	imgOtxNumbersOtx_1Svg,
	imgOtxNumbersOtx_2Svg,
	imgOtxNumbersOtx_3Svg,
	imgOtxNumbersOtx_4Svg,
	imgOtxNumbersOtx_5Svg,
	imgOtxNumbersOtx_6Svg,
	imgOtxNumbersOtx_7Svg,
	imgOtxNumbersOtx_8Svg,
	imgOtxNumbersOtx_9Svg,
} from '~/src/images';
import './OtxNumber.scss';

const OtxNumber = ({
	otxNumber,
	className,
	isSmall = false,
}: {
	otxNumber: number;
	className?: string;
	isSmall?: boolean;
}) => {
	const images = [
		imgOtxLogoSvg,
		imgOtxNumbersOtx_1Svg,
		imgOtxNumbersOtx_2Svg,
		imgOtxNumbersOtx_3Svg,
		imgOtxNumbersOtx_4Svg,
		imgOtxNumbersOtx_5Svg,
		imgOtxNumbersOtx_6Svg,
		imgOtxNumbersOtx_7Svg,
		imgOtxNumbersOtx_8Svg,
		imgOtxNumbersOtx_9Svg,
		imgOtxNumbersOtx_10Svg,
		imgOtxNumbersOtx_11Svg,
		imgOtxNumbersOtx_12Svg,
		imgOtxNumbersOtx_13Svg,
		imgOtxNumbersOtx_14Svg,
		imgOtxNumbersOtx_15Svg,
		imgOtxNumbersOtx_16Svg,
		imgOtxNumbersOtx_17Svg,
	];

	if (!otxNumber) {
		return (
			<img
				className={`otx-number ${className ? className : ''} ${isSmall ? 'small' : ''}`}
				src={images[0]}
				alt={`OTX`}
			/>
		);
	}

	return (
		<img
			className={`otx-number ${className ? className : ''} ${isSmall ? 'small' : ''}`}
			src={images[otxNumber]}
			alt={`OTX${otxNumber}`}
		/>
	);
};

export default OtxNumber;
