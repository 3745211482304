import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DocumentContext from '../../overtime-lib/src/www/contexts/Document';
import './PhotoVideo.scss';

const _cdnify = (url: string, location: URL | Location) =>
	location.hostname === 'localhost' && (url.startsWith('/public') || url.split('/').length < 3) //Local only path
		? url
		: url.startsWith('/public') //Local resources
			? `https://images.overtime.tv/${location.host}${url}`
			: `https://images.overtime.tv${url}`; //S3 resources

const getSource = (
	src: string,
	options: { quality?: number; noindex?: boolean; width?: number; height?: number; format?: string; crf?: number } = {
		noindex: false,
		format: 'webp',
	},
) => {
	try {
		const url = new URL(src, 'http://localhost'); //Use a base so we can operate on path-only sources

		for (const key in options) {
			if (!options[key]) {
				continue;
			}
			url.searchParams.delete(key);
			url.searchParams.append(key, options[key]);
		}
		return `${url.pathname}${url.search}`;
	} catch (error) {
		console.error(error);
		return '';
	}
};

const _Video = ({ videoSrc }: { videoSrc: string }) => {
	const videoRef = useRef<HTMLVideoElement>();

	// Video must be set to default muted on load otherwise iphone won't autoplay
	useEffect(() => {
		videoRef.current.defaultMuted = true;
		videoRef.current.muted = true;
	}, []);

	return (
		<video autoPlay={true} muted={true} loop={true} playsInline={true} ref={videoRef}>
			{/* Apple browsers want this codec */}
			<source src={videoSrc} type="video/mp4; codecs=hvc1" />
			{/* Chrome wants this codec */}
			<source src={videoSrc} type="video/mp4; codecs=hev1.1.6.L93.B0" />
			{/* <source src={imgLoop_28H265Mp4} type="video/mp4 maybe" /> */}
			{/* h264 fallback */}
			<source src={videoSrc} />
		</video>
	);
};

const PhotoVideo = ({
	src,
	width,
	height,
	mobileWidth,
	quality = 70,
	...rest
}: {
	width?: number;
	height?: number;
	mobileWidth?: number;
	quality?: number;
} & React.ImgHTMLAttributes<HTMLImageElement>) => {
	const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);
	const desktopPlaceholderSrc = getSource(src, { width, quality: 1, noindex: true });
	const placeholderSrc = getSource(desktopPlaceholderSrc, { width: mobileWidth });
	const mobileSrc = getSource(src, { width: mobileWidth });
	const fullSrc = getSource(src, { width, quality });
	const videoSrc = getSource(src, { format: undefined });
	const videoThumbnailSrc = getSource(`videos.overtime.tv/v2/${src}`, {
		format: 'jpg',
		crf: 10,
		width: width,
		height: height ? height : (width / 16) * 9,
	});

	const fullImageRef = (ref: HTMLImageElement) => {
		if (!ref) {
			return;
		}
		ref.onload = () => {
			setIsFullImageLoaded(true);
		};
		if (ref.complete) {
			setIsFullImageLoaded(true);
		}
	};

	const { location } = useContext(DocumentContext);
	const cdnify = useCallback((url: string) => _cdnify(url, location), [location]);

	return (
		<div className="PhotoVideo">
			{videoSrc.includes('.mp4') ? (
				<>
					<picture className="VideoPlaceholder">
						<source srcSet={cdnify(videoThumbnailSrc)} media={`(min-width: 1024px)`} />
						<img src={cdnify(videoThumbnailSrc)} {...rest} />
					</picture>
					<_Video videoSrc={cdnify(videoSrc)} />
				</>
			) : (
				<>
					<picture className="PicturePlaceholder">
						<source srcSet={cdnify(desktopPlaceholderSrc)} media={`(min-width: 1024px)`} />
						<img src={cdnify(placeholderSrc)} {...rest} />
					</picture>
					{mobileWidth ? (
						<picture className="PictureFull">
							<source srcSet={cdnify(fullSrc)} media={`(min-width: 1024px)`} />
							<img ref={fullImageRef} src={cdnify(mobileSrc)} {...rest} />
						</picture>
					) : (
						<img ref={fullImageRef} className="PictureFull" src={cdnify(fullSrc)} {...rest} />
					)}
				</>
			)}
		</div>
	);
};

export default PhotoVideo;
