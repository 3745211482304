import { OtxEvent } from './OtxEvent';
import { OtxFighter } from './OtxFighter';
import { OvertimeEntity } from './OvertimeEntity';
import { OvertimeEntityLocation } from './OvertimeEntityLocation';

export interface OtxFight extends OvertimeEntity, OvertimeEntityLocation {
	draftkings_fight_id?: string;
	draftkings_fighter1_odds?: number;
	draftkings_fighter2_odds?: number;
	fight_type?: string;
	is_hidden: boolean;
	is_overtime: boolean;
	is_title_bout: boolean;
	title_bout_name: string;
	is_tournament_fight: boolean;
	name?: string;
	next_tournament_otx_fight_id?: string;
	number_of_rounds: number;
	starts_at_timezone: string;
	starts_at_utc_offset: number;
	starts_at: string;
	weight_class?: string;
	win_by?: string;
	youtube_url?: string;
	ending_round_number?: number;

	otx_event_id?: string;
	otx_fighter1_id?: string;
	otx_fighter2_id?: string;
	otx_winner_id?: string;

	otx_event: OtxEvent;
	otx_fighter1?: OtxFighter;
	otx_fighter2?: OtxFighter;
	otx_winner?: OtxFighter;
}

export enum FightTypes {
	MainEvent = 'Main Event',
	CoMainEvent = 'Co-Main Event',
	Featured = 'Featured',
}

export enum TournamentRounds {
	FirstRound = 'First Round',
	SemiFinals = 'Semi-Finals',
	Championship = 'Championship',
}

export enum FightResults {
	UD = 'Unanimous Decision',
	MD = 'Majority Decision',
	SD = 'Split Decision',
	TKO = 'T.K.O.',
	RTD = 'R.T.D.',
	KO = 'K.O.',
}

export enum FightWinByTypes {
	RTD = 'RTD',
	TKO = 'TKO',
	UD = 'UD',
	MD = 'MD',
	SD = 'SD',
	KO = 'KO',
}

export const EarlyFightStopReasons: FightWinByTypes[] = [FightWinByTypes.RTD, FightWinByTypes.TKO, FightWinByTypes.KO];

export const IsWinner = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_winner_id !== null && fight?.otx_winner_id == fighter?.id;
};

export const IsLoser = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_winner_id !== null && fight?.otx_winner_id !== fighter?.id;
};

export const IsOvertime = (fight: OtxFight) => {
	return fight?.otx_winner_id !== null && fight?.is_overtime;
};

export const GetOpponent = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_fighter1_id === fighter?.id ? fight?.otx_fighter2 : fight?.otx_fighter1;
};

export const GetTournamentRound = (tournamentFights: OtxFight[], fight: OtxFight) => {
	if (!tournamentFights || !fight) {
		return null;
	}

	const rounds = Object.values(TournamentRounds);
	let round = rounds.length;

	let current = fight;
	while (current?.next_tournament_otx_fight_id) {
		round--;
		current = tournamentFights.find((f) => f.id == current.next_tournament_otx_fight_id);
	}

	return rounds[round - 1];
};

// Parses the fight results and returns displayable results (e.g. UD -> Unanimous Decision)
export const GetFightResultsDisplayText = (fight: OtxFight) => {
	if (!fight.win_by) {
		return null;
	}
	return FightResults[fight.win_by] || fight.win_by;
};

export const IsMoneyRoundWin = (fight: OtxFight): boolean => {
	if (fight.win_by === null || fight.ending_round_number === null) {
		return null;
	}

	if (EarlyFightStopReasons.includes(fight.win_by as FightWinByTypes)) {
		// check to see if the ending round is 1 or the last round
		if (fight.ending_round_number === 1 || fight.ending_round_number === fight.number_of_rounds) {
			return true;
		}
	}

	return false;
};

export const GetOddsDisplayText = (odds: number): string => {
	if (odds === null) return null;
	if (odds > 0) {
		return `+${odds}`;
	}
	return odds.toString();
};

export const IsFightComplete = (fight: OtxFight): boolean => {
	return fight?.otx_winner_id !== null;
};

// Returns true if any of the fights have a draftkings id otherwise false
export const FightsContainDraftkingsId = (fights: OtxFight[]): boolean => {
	if (!fights) return false;
	return fights.some((fight) => fight.draftkings_fight_id);
};
